// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig : {
    apiKey: 'AIzaSyB5CKHdc5v3FqhfITJOVdsVB4czpg_W84A',
    authDomain: 'specialvan-5b081.firebaseapp.com',
    projectId: 'specialvan-5b081',
    storageBucket: 'specialvan-5b081.appspot.com',
    messagingSenderId: '1049006122057',
    appId: '1:1049006122057:web:ea5ba71754e8ed253fcb98'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
