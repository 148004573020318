<div class="container">
  <img src="/assets/images/logo.png" alt="">
  <h1>Coming Soon</h1>
  <p>
    Viale Italia, 22
    46100 Mantova | MN
  </p>
  <p>
    <a href="mailto:info@specialvan.it">info@specialvan.it</a>
  </p>
</div>
